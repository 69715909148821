export const citiesByState = {
  "Delhi": [
    "Ashok Vihar",
    "Chanakyapuri",
    "Chandni Chowk",
    "Connaught Place",
    "Civil Lines",
    "Daryaganj",
    "Defence Colony",
    "Delhi",
    "Dilshad Garden",
    "Dwarka",
    "East Delhi",
    "Faridabad",
    "Geeta Colony",
    "Greater Noida",
    "Green Park",
    "Gurgaon",
    "Hauz Khas",
    "Hauz Qazi",
    "Jangpura",
    "Karol Bagh",
    "Kailash Colony",
    "Kamla Nagar",
    "Khan Market",
    "Kirti Nagar",
    "Lajpat Nagar",
    "Laxmi Nagar",
    "Loni",
    "Malviya Nagar",
    "Mayur Vihar",
    "Model Town",
    "Mukherjee Nagar",
    "Munirka",
    "Najafgarh",
    "Nehru Place",
    "New Delhi",
    "Noida",
    "North Delhi",
    "Okhla",
    "Paharganj",
    "Patparganj",
    "Pitampura",
    "Preet Vihar",
    "Punjabi Bagh",
    "Rajouri Garden",
    "Rohini",
    "Saket",
    "Sarita Vihar",
    "Sadar Bazaar",
    "Safdarjung",
    "Shahdara",
    "Shalimar Bagh",
    "South Delhi",
    "Sadar Bazaar",
    "Vasant Kunj",
    "Vasant Vihar",
    "Vikas Puri",
    "West Delhi"
],
    "Haryana":
    [
      "Ambala",
      "Ambala Cantt",
      "Asankhurd",
      "Babain",
      "Bahadurgarh",
      "Ballabgarh",
      "Barara",
      "Barwala",
      "Bawal",
      "Bhiwani",
      "Charkhi Dadri",
      "Cheeka",
      "Dharuhera",
      "Ellenabad",
      "Faridabad",
      "Fatehabad",
      "Ganaur",
      "Gharaunda",
      "Gohana",
      "Gorakhpur",
      "Gurgaon",
      "Hansi",
      "Hasangarh",
      "Hisar",
      "Hodal",
      "Indri",
      "Jagadhri",
      "Jakhal",
      "Jhajjar",
      "Jind",
      "Julana",
      "Kaithal",
      "Kalanaur",
      "Kalka",
      "Karnal",
      "Kharkhauda",
      "Kosli",
      "Kurukshetra",
      "Ladwa",
      "Mahendragarh",
      "Mandi Dabwali",
      "Naraingarh",
      "Narwana",
      "Narnaund",
      "Narnaul",
      "Narwana",
      "Nilokheri",
      "Nuh",
      "Palwal",
      "Panchkula",
      "Panipat",
      "Pehowa",
      "Pinjore",
      "Pundri",
      "Rania",
      "Ratia",
      "Rewari",
      "Rohtak",
      "Safidon",
      "Samalkha",
      "Shahabad",
      "Sirsa",
      "Siwani",
      "Sohna",
      "Sonipat",
      "Thanesar",
      "Tharu",
      "Tohana",
      "Tosham",
      "Uchana",
      "Yamuna Nagar"
    ],

    "Punjab": [
      "Abohar",
      "Ahmedgarh",
      "Amritsar",
      "Barnala",
      "Batala",
      "Bathinda",
      "Begowal",
      "Bhadaur",
      "Bhikhiwind",
      "Budhlada",
      "Chandigarh",
      "Dakha",
      "Dera Baba Nanak",
      "Dera Bassi",
      "Dharamkot",
      "Dhuri",
      "Dinanagar",
      "Ellenabad",
      "Fatehgarh Churian",
      "Fatehgarh Sahib",
      "Faridkot",
      "Fazilka",
      "Firozpur",
      "Ghanaur",
      "Gidderbaha",
      "Gobindgarh",
      "Gurdaspur",
      "Hoshiarpur",
      "Jagraon",
      "Jaitu",
      "Jalalabad",
      "Jalalabad West",
      "Jalandhar",
      "Jandiala",
      "Jhunir",
      "Joga",
      "Kahnuwan",
      "Kailash Colony",
      "Kapurthala",
      "Karol Bagh",
      "Kharar",
      "Khem Karan",
      "Kirti Nagar",
      "Kosli",
      "Kot Fatta",
      "Kotkapura",
      "Kurali",
      "Lajpat Nagar",
      "Laxmi Nagar",
      "Lehra Gaga",
      "Loni",
      "Ludhiana",
      "Majitha",
      "Malviya Nagar",
      "Malerkotla",
      "Mandi Dabwali",
      "Mandi Gobindgarh",
      "Mansa",
      "Maur",
      "Moga",
      "Mohali",
      "Morinda",
      "Mukherjee Nagar",
      "Muktsar",
      "Mullanpur",
      "Nabha",
      "Nakodar",
      "Nangal",
      "Nawa Shahar",
      "Nawanshahr",
      "Nehru Place",
      "Nurmahal",
      "Paharganj",
      "Pakhowal",
      "Patiala",
      "Patti",
      "Payal",
      "Phagwara",
      "Pundri",
      "Qadian",
      "Rajpura",
      "Ramdass",
      "Rampura",
      "Rampura Phul",
      "Rampuraphul",
      "Rania",
      "Ratia",
      "Raikot",
      "Rampuraphul",
      "Ropar",
      "Rustam",
      "Rurka Kalan",
      "Safdarjung",
      "Sahnewal",
      "Saket",
      "Sanaur",
      "Sangat",
      "Sangrur",
      "Sardulgarh",
      "Sarhind",
      "Sarita Vihar",
      "Sawai Madhopur",
      "Sekhewal",
      "Sekhon Nagar",
      "Sekhwan",
      "Shahabad",
      "Shahdara",
      "Shahkot",
      "Shalimar Bagh",
      "Shekhpura",
      "Sirkap",
      "Sohna",
      "Sultanpur Lodhi",
      "Sunam",
      "Surti",
      "Talwandi Bhai",
      "Talwandi Sabo",
      "Talwara",
      "Tappa",
      "Tappa Mandi",
      "Tarn Taran",
      "Tarn Taran Sahib",
      "Thanesar",
      "Tosham",
      "Uchana",
      "Uklanamandi",
      "Vasant Kunj",
      "Vasant Vihar",
      "Vikas Puri",
      "Vivek Vihar",
      "Zira",
      "Zirakpur"
      // Add more cities and towns in Punjab as needed
  ],
  
    "Uttarakhand": ["Dehradun","Haridwar","Roorkee","Nanital"]
    // Add more states and their cities as needed
  };